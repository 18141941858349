import React from "react";
import { graphql, StaticQuery } from "gatsby"
import Layout from "../../layouts/index";

import { useIntl, Link, FormattedMessage } from "gatsby-plugin-intl"

const Promotions = (props) => {
	const intl = useIntl();
	const terms = props.data.allMarkdownRemark.edges;
	return (
		<Layout className="page-promotion" path={props.location.pathname}>
			promotion
		</Layout>
	);
};

const termsQuery = graphql`
  query {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { regex: "/promotions/" }
      }
    ) {
      edges {
        node {
          excerpt
          frontmatter {
            title
            path
          }
        }
      }
    }
  }
`;

export default props => (
	<StaticQuery
		query={termsQuery}
		render={data => (
			<Promotions location={props.location} props data={data} {...props} />
		)}
	/>
)
